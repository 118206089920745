<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-input(v-model="listQuery.keyword" clearable style="width: 200px;" placeholder="用户名/操作").filter-item.ml-5
            el-date-picker(v-model="listQuery.date" type="datetimerange"  value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期").filter-item.ml-5
            el-button(v-permission="['order:activity-order:list']" type="primary" icon="el-icon-search" @click="getList").filter-item.ml-5 查找
 
        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="left" label="登录名" prop="username" sortable width="100px")
            el-table-column(align="left" label="用户名" prop="name" sortable width="100px")
            el-table-column(align="right" label="操作日期" prop="request_time" sortable width="150px")
                template(slot-scope="scope")
                    span {{scope.row.request_time | formatDateAndTime}}
            el-table-column(align="right" label="操作类型" prop="opt_type" sortable width="150px")
                template(slot-scope="scope") {{scope.row.opt_type}}
            el-table-column(align="center" label="详情").small-padding.fixed-width
                template(slot-scope="scope")
                  span [{{scope.row.level_1 }}] - 
                  span [{{scope.row.level_2 }}] - 
                  span [{{scope.row.level_3 }}]

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

</template>

<script>
import * as system from "../../api/system";

import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "AppLogList",
  components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        keyword: "",
        date: [],
        page: 1,
        limit: 20,
        sort: "creation_date",
        order: "desc"
      }
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    formatRole(roleId) {
      for (let i = 0; i < this.roleOptions.length; i++) {
        if (roleId === this.roleOptions[i].value) {
          return this.roleOptions[i].label;
        }
      }
      return "";
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await system.getAppLog(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    }
  }
};
</script>
